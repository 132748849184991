<template lang="html">
	<article class="step">
		<h2>{{ $t(`${sector}.a2.title`) }}</h2>
		<p>
			{{ $t(`${sector}.a2.desc`) }}
			<strong>{{ $t('global.tip.mandatory') }}</strong>
		</p>
		<step-energy :sector="sector" abast="a2" :valid.sync="valid.fixes" required />
	</article>
</template>

<script>
import StepMixin from '@/mixins/StepMixin';
import StepEnergy from '../blocks/StepEnergy.vue';

export default {
	name: 'StepA2',
	mixins: [StepMixin],
	components: { StepEnergy },
	mounted() { this.$emit('validate', true); },
};
</script>
